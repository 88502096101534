import usePlatformApi from "./platformApi";

export default function useStore(storeId, params = {}) {
  const { api, ...rest } = usePlatformApi(
    storeId
      ? `${process.env.NEXT_PUBLIC_API_HOST}/api/v2/platform/stores/${storeId}`
      : null,
    { params }
  );

  const create = async (body) =>
    (await api.post("stores", { store: body })).data;

  const duplicate = async (store_id) =>
    (await api.post(`stores/${store_id}/duplicate`)).data;

  const update_allowed_domains_and_emails = async (params) =>
    api
      .post(`stores/${storeId}/update_allowed_domains_and_emails`, params)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        } else {
          throw error;
        }
      });

  const update_pod_prices = async (params) =>
    api
      .post(`stores/${storeId}/update_pod_prices`, params)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        } else {
          throw error;
        }
      });

  const generate_mockups = async (params) =>
    api
      .post(`stores/${storeId}/generate_mockups`, params)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        } else {
          throw error;
        }
      });

  return {
    ...rest,
    create,
    duplicate,
    update_allowed_domains_and_emails,
    update_pod_prices,
    generate_mockups,
  };
}
