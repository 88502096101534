import * as Tooltip from "@radix-ui/react-tooltip";
import { useEffect, useState } from "react";
import { MdNotificationsActive, MdNotificationsOff } from "react-icons/md";
import OrderNotificationContext from "@/components/OrderNotificationContext";

export default function OrderNotificationToggle() {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    localStorage &&
      setEnabled(
        () => global?.localStorage?.getItem("enableOrderNotification") == "true"
      );
  }, []);

  const Icon = enabled ? MdNotificationsActive : MdNotificationsOff;

  return (
    <OrderNotificationContext.Consumer>
      {({ bindOrderNotification, unbindOrderNotification }) => (
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <button
              className="user-select-none -m-3 cursor-pointer rounded-full p-3 hover:bg-gray-50"
              onClick={() => {
                const newEnabled = !enabled;
                console.log("toggle", newEnabled);
                localStorage?.setItem("enableOrderNotification", newEnabled);
                setEnabled(newEnabled);
                newEnabled
                  ? bindOrderNotification()
                  : unbindOrderNotification();
              }}
              type="button"
            >
              <Icon className="h-6 w-6 text-gray-500" />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="z-10 rounded bg-black px-2 py-1 text-xs text-gray-100">
              Real-time order notification
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      )}
    </OrderNotificationContext.Consumer>
  );
}
