import useCurrentStore from "@/lib/useCurrentStore";
import Head from "next/head";

export default function Title({ pageName }) {
  const { data: currentStore } = useCurrentStore();
  const storeName = currentStore?.name;
  const titleText = `${pageName ? pageName + " – " : ""}${
    storeName ? storeName + " - " : ""
  }PromoHunt`;

  return (
    <Head>
      <title>{titleText}</title>
    </Head>
  );
}
