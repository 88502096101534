import Pagination, {
  extractPaginationDataFromJSONApi,
  PER_PAGE,
} from "@/components/Pagination";
import Search from "@/components/Search";
import Skeleton from "@/components/Skeleton";
import StoreStatusBadge from "@/components/StoreStatusBadge";
import Title from "@/components/Title";
import { dateTime } from "@/lib/date";
import useStoreList from "@/lib/useStoreList";
import PromoHuntLogo from "@/public/promohunt.svg";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  HiMenu,
  HiOutlineExternalLink,
  HiPlus,
  HiShoppingBag,
  HiX,
} from "react-icons/hi";
import OrderNotificationToggle from "@/components/OrderNotificationToggle";
import LogoutButton from "@/components/LogoutButton";

export default function Home() {
  return (
    <div>
      <Title pageName="Stores" />
      <Navbar />
      <Main />
    </div>
  );
}

function Navbar() {
  return (
    <header className="promohunt_app__shell__header fixed left-0 right-0 top-0">
      <nav className="bg-white shadow">
        <div className="mx-auto max-w-6xl px-3">
          <div className="flex h-16 justify-between">
            <div className="flex px-2">
              <div className="flex flex-shrink-0 items-center">
                <Link href="/">
                  <a className="relative mr-1 block h-6">
                    <Image src={PromoHuntLogo} alt="PromoHunt" />
                  </a>
                </Link>
              </div>
            </div>

            <div className="mr-2 flex h-16 items-center space-x-6">
              <OrderNotificationToggle />
              <LogoutButton className="button button--text ml-6" />
            </div>

            <div className="flex items-center md:hidden">
              {/* <!-- Mobile menu button --> */}
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {/* <!--
              Icon when menu is closed.

              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            --> */}
                <HiMenu className="block h-6 w-6" />

                {/* <!--
              Icon when menu is open.

              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            --> */}
                <HiX className="hidden h-6 w-6" />
              </button>
            </div>
            <div className="!lg:flex todo hidden lg:ml-4 lg:items-center">
              {/* <!-- Profile dropdown --> */}
              <div className="relative ml-4 flex-shrink-0">
                <div>
                  <button
                    type="button"
                    className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open Menu</span>
                    {/* <img className="h-8 w-8 rounded-full" src="" alt="" /> */}
                  </button>
                </div>

                {/* <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
                <div
                  className="todo absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex="-1"
                >
                  {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-0"
                  >
                    Item One
                  </a>

                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex="-1"
                    id="user-menu-item-2"
                  >
                    Item Two
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        {/* <MobileMenu/> */}
      </nav>
    </header>
  );
}

function Main() {
  return (
    <main className="mx-auto mt-16 max-w-6xl px-5 py-12">
      <Heading />
      <Stores />
    </main>
  );
}

function Heading() {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-xl font-semibold leading-6">Stores</h1>
      <div>
        <Link href="/new-store">
          <a className="button button--primary">New Store</a>
        </Link>
      </div>
    </div>
  );
}

function Stores() {
  return (
    <div className="my-4 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="hidden px-4 sm:px-6">
        <StoreListTabs />
      </div>
      <div className="p-4">
        <Search placeholder="Search by name or url" />
      </div>
      <div className="overflow-y-auto">
        <StoreList />
      </div>
    </div>
  );
}

function StoreListTabs() {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* <!-- Use an "onChange" listener to redirect the user to the selected tab URL. --> */}
        <select
          id="tabs"
          name="tabs"
          className="my-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm"
          defaultValue="All"
        >
          <option>All</option>

          <option>Open</option>

          <option>Closed</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {/* <!-- Current: "border-blue-600 text-primary-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" --> */}
            <a
              href="#"
              className="flex whitespace-nowrap border-b-2 border-gray-700 px-1 py-4 text-sm font-medium"
              aria-current="page"
            >
              All
            </a>
            <a
              href="#"
              className="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
            >
              Open
            </a>
            <a
              href="#"
              className="flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
            >
              Closed
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

function usePaginatedStores() {
  const {
    query: { q, page },
  } = useRouter();

  return useStoreList({
    "filter[name_or_url_cont]": q,
    sort: "-created_at",
    page,
    per_page: PER_PAGE,
  });
}

function StoreList() {
  const { query } = useRouter();
  const { data: stores, meta, links, isLoading } = usePaginatedStores();

  const isEmpty = !isLoading && !stores;

  return (
    <>
      {isEmpty && <EmptyState />}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {["Name", "Type", "Status", "Created"].map((label) => (
              <th
                key={label}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {isLoading && <SkeletonRows />}
          {!isLoading &&
            stores?.map((store) => (
              <tr className="cursor-pointer hover:bg-gray-50" key={store.id}>
                <td className="relative whitespace-nowrap px-6 py-4">
                  <div className="hover:gray-200 text-sm font-medium">
                    {store.name}
                  </div>
                  <div
                    className="group relative z-auto mt-1 inline-flex
                      items-center pr-2 text-sm text-gray-500 hover:text-blue-700"
                  >
                    <span className="mr-2">{store.url}</span>
                    <HiOutlineExternalLink
                      className="invisible h-4 w-4 text-gray-500
                        group-hover:visible
                        "
                    />
                    <a
                      href={`https://${store.url}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className="absolute inset-0 z-10"
                    />
                  </div>
                  <Link href={`/s/${store.id}`} key={store.id} passHref>
                    <a className="absolute inset-0" />
                  </Link>
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <span className="text-sm text-gray-500">
                    {store.gift_store ? "Gift" : "Payment"}
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <StoreStatusBadge store={store} />
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <div className="text-sm text-gray-500">
                    {dateTime(store.created_at)}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="overflow-hidden rounded-b-md pt-3">
        <Pagination
          currentPageNo={query.page ? parseInt(query.page) : 1}
          {...extractPaginationDataFromJSONApi({ meta, links })}
        />
      </div>
    </>
  );
}

function EmptyState() {
  return (
    <div className="py-12 text-center">
      <HiShoppingBag className="mx-auto h-12 w-12 text-gray-400" />

      <h3 className="mt-2 text-sm font-medium">No stores yet</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new store.
      </p>
      <div className="mt-6">
        <Link href="/new-store">
          <a
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            {/* <!-- Heroicon name: solid/plus --> */}
            <HiPlus className="-ml-1 mr-2 h-5 w-5" />
            New Store
          </a>
        </Link>
      </div>
    </div>
  );
}

function SkeletonRows() {
  return (
    <>
      {[...Array(25)].map((_, i) => (
        <tr key={i}>
          <td className="px-6 py-4">
            <Skeleton className="h-6 w-[32ch] text-sm font-medium" />
          </td>
          <td className="px-6 py-4 ">
            <Skeleton className="mr-2 h-6 w-[12ch] text-sm" />
          </td>
          <td className="px-6 py-4">
            <Skeleton className="h-6 w-[12ch] text-sm" />
          </td>
          <td className="px-6 py-4">
            <Skeleton className="h-6 w-[12ch] text-sm" />
          </td>
        </tr>
      ))}
    </>
  );
}
