import { clearAuth } from "@/lib/auth";

export default function LogoutButton(props) {
  const handleLogout = () => {
    clearAuth();
    window.location.href = "/login";
  };

  return (
    <button onClick={handleLogout} type="button" {...props}>
      Log out
    </button>
  );
}
