import { isPast, parseISO } from "date-fns";
import { dateTime } from "@/lib/date";
import Link from "next/link";
import * as Tooltip from "@radix-ui/react-tooltip";

export default function StoreStatusBadge({ store }) {
  const closeOnDate = store.closed_on ? parseISO(store.closed_on) : null;
  const isClosedPast = store.closed_on ? isPast(closeOnDate) : false;

  const status = closeOnDate ? (isClosedPast ? "closed" : "scheduled") : "open";

  const classes = {
    open: "bg-green-100 text-green-800",
    scheduled: "bg-yellow-100 text-yellow-800",
    closed: "bg-gray-100 text-gray-800",
  };

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <span
          className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium capitalize ${classes[status]}`}
        >
          <Link
            href={`/s/${store.id}/settings#settings-general-store-close-date`}
          >
            <a>{status}</a>
          </Link>
        </span>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content>
          <Tooltip.Arrow />
          <div className="rounded bg-black px-2 py-1 text-xs text-gray-100">
            {status == "open" && "No close date set"}
            {status == "scheduled" && `Closing on ${dateTime(store.closed_on)}`}
            {status == "closed" && `Closed on ${dateTime(store.closed_on)}`}
          </div>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}
