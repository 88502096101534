import { useRef } from "react";
import { useRouter } from "next/router";
import { HiSearch } from "react-icons/hi";

export default function SearchFilter({ placeholder = "Search" }) {
  const searchInputRef = useRef();

  const router = useRouter();

  const handleSubmit = (e) => {
    e.preventDefault();
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        q: searchInputRef.current.value,
        page: 1,
      },
    });
  };

  return (
    <div className="flex w-full">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <HiSearch className="h-5 w-5 text-gray-400" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex space-x-2">
            <input
              ref={searchInputRef}
              id="search"
              name="search"
              className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 sm:text-sm"
              placeholder={placeholder}
              type="search"
              defaultValue={router.query.q}
            />
            <button className="button button--secondary">Search</button>
          </div>
        </form>
      </div>
    </div>
  );
}
