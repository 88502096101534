import { format, parseISO } from "date-fns";

// Jun 23, 2022
const DATE_FORMAT = "LLL d, yyyy";

// 7:39 pm
const TIME_FORMAT = "h:mm a";

// Jun 23, 2022 at 7:39 pm
const FORMAT = `${DATE_FORMAT} 'at' ${TIME_FORMAT}`;

export function dateTime(timestamp) {
  const ts = parseISO(timestamp);
  return format(ts, FORMAT);
}

export function date(timestamp) {
  const ts = parseISO(timestamp);
  return format(ts, DATE_FORMAT);
}

export function currentTimeZone() {
  return new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
}
