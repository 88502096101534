import Link from "next/link";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useRouter } from "next/router";
import usePagination from "@lucasmogari/react-pagination";

export const PER_PAGE = 25;

export function extractPaginationDataFromJSONApi({ meta, links }) {
  return {
    totalItems: meta?.total_count,
    totalPages: meta?.total_pages,
    prevPageNo:
      links && new URLSearchParams(new URL(links.prev).search).get("page"),
    nextPageNo:
      links && new URLSearchParams(new URL(links.next).search).get("page"),
  };
}

export default function Pagination({
  totalItems,
  totalPages,
  currentPageNo,
  prevPageNo,
  nextPageNo,
  pageSize = PER_PAGE,
}) {
  const { query, pathname } = useRouter();

  const pagination = usePagination({
    totalPages,
    totalItems,
    page: currentPageNo,
    itemsPerPage: pageSize,
    numbers: true,
    arrows: true,
  });

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          href={{
            pathname,
            query: {
              ...query,
              page: prevPageNo,
            },
          }}
        >
          <a className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Previous
          </a>
        </Link>
        <Link
          href={{
            pathname,
            query: {
              ...query,
              page: nextPageNo,
            },
          }}
        >
          <a className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Next
          </a>
        </Link>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {/* Hack: pagination.totalItems is not 0 even if the totalItemsprop is 0. A possible bug in the lib */}
          {totalItems > 0 ? (
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{pagination.fromItem}</span>{" "}
              to <span className="font-medium">{pagination.toItem}</span> of{" "}
              <span className="font-medium">{pagination.totalItems}</span>{" "}
              results
            </p>
          ) : (
            <p className="text-sm text-gray-700">0 results</p>
          )}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {/* Inspired from https://github.com/lucasmogari/react-pagination/blob/29a2c19a7dd68463419826176ac03b9c449aa749/packages/docs/components/Pagination.js#L12 */}
            {[...Array(pagination.size)].map((_, i) => {
              const pageItem = pagination.getPageItem(i);

              return (
                <div key={i}>
                  {pageItem.page === "previous" ? (
                    <Link
                      href={{
                        pathname,
                        query: {
                          ...query,
                          page: prevPageNo,
                        },
                      }}
                    >
                      <a className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span className="sr-only">Previous</span>
                        <HiChevronLeft className="h-5 w-5" />
                      </a>
                    </Link>
                  ) : pageItem.page === "next" ? (
                    <Link
                      href={{
                        pathname,
                        query: {
                          ...query,
                          page: nextPageNo,
                        },
                      }}
                    >
                      <a className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span className="sr-only">Next</span>
                        <HiChevronRight className="h-5 w-5" />
                      </a>
                    </Link>
                  ) : pageItem.page === "gap" ? (
                    <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                      ...
                    </span>
                  ) : (
                    <Link
                      href={{
                        pathname,
                        query: {
                          ...query,
                          page: pageItem.page,
                        },
                      }}
                    >
                      <a
                        aria-current="page"
                        className={`relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-20 ${
                          pageItem.current
                            ? "z-10  bg-gray-100 text-gray-800"
                            : "bg-white text-gray-500 hover:bg-gray-50 hover:text-gray-800 "
                        }`}
                      >
                        {pageItem.page}
                      </a>
                    </Link>
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
