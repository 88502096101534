import { useRouter } from "next/router";
import useStore from "./useStore";

export default function useCurrentStore(params = {}) {
  const router = useRouter();
  const { storeId } = router.query;
  const { data = {}, ...rest } = useStore(storeId, params);

  return { ...data, ...rest };
}
